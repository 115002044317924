import {
  GET_IN_PROGRESS_SHOOTS_SUCCESS,
  GET_IN_PROGRESS_SHOOTS,
  GET_IN_PROGRESS_SHOOTS_FAILED,
} from 'actions/shoots';

const initialState = {
  loading: false,
  inprogressShoots: {
    items: [],
    meta: {},
  },
};

export const ShootsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_IN_PROGRESS_SHOOTS:
      return {
        ...state,
        loading: true,
      };
    case GET_IN_PROGRESS_SHOOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        inprogressShoots: {
          items: payload.items,
          meta: payload.meta,
        },
      };
    case GET_IN_PROGRESS_SHOOTS_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
