import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DashboardLayout from 'layouts/Dashboard';
import PhotographerNavigation from '../containers/Photographer/Navigation';

const AllShoots = lazy(() =>
  import('containers/Photographer/Shoots/AllShoots')
);
const UploadStation = lazy(() =>
  import('containers/Photographer/Shoots/UploadStation')
);
const SingleShoot = lazy(() =>
  import('containers/Photographer/Shoots/SingleShoot')
);
const Calendar = lazy(() => import('containers/Photographer/Calendar'));
const ProfileSettings = lazy(() =>
  import('containers/Photographer/Account/ProfileSettings')
);
// import Work from 'containers/Photographer/Account/WorkHistory';

const PhotographerRoutes = () => {
  return (
    <DashboardLayout navigation={<PhotographerNavigation />}>
      <Switch>
        <Route exact path="/shoots" component={AllShoots} />
        <Route path="/uploadstation" component={UploadStation} />
        <Route path="/shoots/:id" component={SingleShoot} />
        <Route path="/calendar" component={Calendar} />
        <Route path="/profilesettings" component={ProfileSettings} />
        <Redirect to="/uploadstation" />
        {/* <Route path="/work" component={Work} /> */}
      </Switch>
    </DashboardLayout>
  );
};

export default PhotographerRoutes;
