import { combineReducers } from 'redux';
import AuthorizationReducer from './Photographer/AuthorizationReducer';
import OnboardingReducer from './Photographer/OnboardingReducer';
import { CategoryReducer } from './Photographer/CategoryReducer';
import { ShootsReducer } from './Photographer/ShootsReducer';
import { JobsReducer } from './Photographer/JobsReducer';

const rootReducer = combineReducers({
  AuthorizationReducer,
  CategoryReducer,
  OnboardingReducer,
  ShootsReducer,
  JobsReducer,
});

export default rootReducer;
