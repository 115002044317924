import {
  SET_STEP,
  GO_TO_NEXT_STEP,
  SET_STEP_AS_VERIFIED,
  SET_VALUE,
  SET_VALUE_PHONE,
  VERIFY_PHONE,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_FAILED,
  CHECK_VERIFY,
  CHECK_VERIFY_SUCCESS,
  CHECK_VERIFY_FAILED,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILED,
  SELECT_CATEGORY,
  SET_VALUE_EQUIPMENT,
  SET_LONG_LAT,
  SET_ADDRESS_DETAILS,
  CHECK_GENERAL_VALIDATION,
  CHECK_EQUIPMENT_VALIDATION,
  UPLOAD_COMPLETED,
  GO_PREVIOUS_STEP,
  SUBMIT_ONBOARDING,
  SUBMIT_ONBOARDING_SUCCESS,
  SUBMIT_ONBOARDING_FAILED,
} from 'actions/onboarding';
import { SET_USER_DATA } from 'actions/authorization';

const initialState = {
  isLoading: false,
  message: '',
  error: false,
  currentStep: 1,
  user: {},
  steps: {
    1: {
      error: 'Please complete your phone verification!',
      completed: false,
      title: '',
    },
    2: {
      error: 'Please fill in your work address!',
      completed: false,
      title: 'Where are you based?',
    },
    3: {
      error: 'Please fill in all general information required!',
      completed: false,
      title: 'General Information',
    },
    4: {
      error: 'Please add your photography equipment!',
      completed: false,
      title: 'Photography',
    },
    5: {
      error: 'Please choose your category application!',
      completed: false,
      title: 'Genre',
    },
    6: {
      error: 'Please upload 3-12 pictures for your chosen category!',
      completed: false,
      title: 'Category',
    },
    7: {
      error: '',
      completed: false,
      title: 'Confirm your details',
    },
  },
  uploadCompleted: false,
  categories: [],
  //Phone Logic State
  phone: null,
  verified: false,
  request_id: null,
  code: null,
  category: '',
  data: {
    id: '',
    email: '',
    phone: '',
    photographer: {
      id: '',
    },
    verify_id: '',
    vat_number: '',
    gender: '',
    birthday: '',
    bio: '',
    prof_since: '',
    address: '',
    latitude: '',
    longitude: '',
    city: '',
    postcode: '',
    cameras: [],
    lenses: [],
    accessories: '[]',
  },
};

const OnboardingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: payload,
        data: {
          ...state.data,
          id: payload.id,
          email: payload.email,
          photographer: {
            id: payload.photographer.id,
          },
        },
        steps: {
          ...state.steps,
          1: {
            error: 'Please complete your phone verification!',
            completed: false,
            title: `Hi ${payload.first_name}`,
          },
        },
      };
    case SET_STEP: {
      const { step } = payload;
      return {
        ...state,
        currentStep: step,
      };
    }
    case SET_VALUE: {
      const { name, value } = payload;
      return {
        ...state,
        data: { ...state.data, [name]: value },
      };
    }
    case SET_VALUE_PHONE: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    }
    case SET_ADDRESS_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          address: payload.address,
          city: payload.city,
          postcode: payload.postcode,
        },
      };
    case VERIFY_PHONE:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        request_id: payload.request_id,
        data: {
          ...state.data,
          verify_id: payload.request_id,
        },
      };
    case VERIFY_PHONE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload,
      };
    case CHECK_VERIFY:
      return {
        ...state,
        isLoading: true,
      };
    case CHECK_VERIFY_SUCCESS:
      return {
        ...state,
        verified: true,
        steps: {
          ...state.steps,
          [1]: { ...state.steps[1], completed: true },
        },
      };
    case CHECK_VERIFY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload,
      };
    case SET_LONG_LAT:
      return {
        ...state,
        data: {
          ...state.data,
          latitude: payload.latitude,
          longitude: payload.longitude,
        },
        steps: {
          ...state.steps,
          [2]: { ...state.steps[2], completed: true },
        },
      };
    case SET_VALUE_EQUIPMENT: {
      const { name, value } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [name]: JSON.stringify(value),
        },
      };
    }
    case SET_STEP_AS_VERIFIED:
      return {
        ...state,
        [payload]: true,
      };
    case GO_TO_NEXT_STEP: {
      const { currentStep } = state;
      const nextStep = currentStep + 1;
      return {
        ...state,
        steps: {
          ...state.steps,
          [currentStep]: { ...state.steps[currentStep], completed: true },
        },
        currentStep: nextStep,
      };
    }
    case GO_PREVIOUS_STEP: {
      const { currentStep } = state;
      if (currentStep === 6) {
        const backStep = currentStep - 3;
        return {
          ...state,
          currentStep: backStep,
        };
      } else {
        const backStep = currentStep - 1;
        return {
          ...state,
          currentStep: backStep,
        };
      }
    }
    case CHECK_GENERAL_VALIDATION: {
      if (
        state.data.birthday &&
        state.data.website &&
        state.data.gender &&
        state.data.prof_since
      ) {
        return {
          ...state,
          steps: {
            ...state.steps,
            [3]: { ...state.steps[3], completed: true },
          },
        };
      }
    }
    case GET_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: payload,
      };
    case GET_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        category: payload,
      };
    case CHECK_EQUIPMENT_VALIDATION: {
      if (
        JSON.parse(JSON.stringify(state.data.cameras)).length > 0 &&
        JSON.parse(JSON.stringify(state.data.lenses)).length > 0
      ) {
        return {
          ...state,
          steps: {
            ...state.steps,
            [4]: { ...state.steps[4], completed: true },
          },
        };
      }
      return { ...state };
    }
    case UPLOAD_COMPLETED: {
      return {
        ...state,
        uploadCompleted: true,
        steps: {
          ...state.steps,
          [6]: { ...state.steps[6], completed: true },
        },
      };
    }
    case SUBMIT_ONBOARDING:
      return {
        ...state,
      };
    case SUBMIT_ONBOARDING_SUCCESS:
      return {
        ...state,
      };
    case SUBMIT_ONBOARDING_FAILED:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
export default OnboardingReducer;
