import React from 'react';
import { Layout, Image, Typography, Space, Row } from 'antd';

const { Footer } = Layout;
const { Text } = Typography;

const styles = {
  footer: {
    padding: '10px 50px',
  },
  text: {
    fontSize: '12px',
  },
};
const _Footer = () => {
  return (
    <Footer style={styles.footer}>
      <Row align="middle" justify="space-around">
        <Space direction="vertical" align="center">
          <Image
            preview={false}
            height={22}
            alt="Perfocal logo"
            src="https://dkk5oy4zs0vc4.cloudfront.net/iconmark-dark.svg"
          />
          <Text style={styles.text}>
            © {new Date().getFullYear()} Perfocal Limited.
          </Text>
        </Space>
      </Row>
    </Footer>
  );
};

export default _Footer;
