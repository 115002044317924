import { createPhotoCertificate, uploadCertificate } from 'api/UploadCategory';
import { openNotificationWithIcon } from 'actions/notification';

export const UPLOAD_PHOTO_CERTIFICATE = 'UPLOAD_PHOTO_CERTIFICATE';
export const UPLOAD_PHOTO_CERTIFICATE_SUCCESS =
  'UPLOAD_PHOTO_CERTIFICATE_SUCCESS';
export const UPLOAD_PHOTO_CERTIFICATE_FAILED =
  'UPLOAD_PHOTO_CERTIFICATE_FAILED';

export const UPLOAD_CERTIFICATE_FINAL = 'UPLOAD_CERTIFICATE_FINAL';
export const UPLOAD_CERTIFICATE_FINAL_SUCCESS =
  'UPLOAD_CERTIFICATE_FINAL_SUCCESS';
export const UPLOAD_CERTIFICATE_FINAL_FAILED =
  'UPLOAD_CERTIFICATE_FINAL_FAILED';

const uploadPhotoCertificate = () => ({ type: UPLOAD_PHOTO_CERTIFICATE });

const uploadPhotoCertificateFailed = (payload) => ({
  type: UPLOAD_PHOTO_CERTIFICATE_FAILED,
  payload,
});

const uploadPhotoCertificateSuccess = (payload) => ({
  type: UPLOAD_PHOTO_CERTIFICATE_SUCCESS,
  payload,
});

export const uploadPhotoCertificateRequest = (payload) => async (dispatch) => {
  dispatch(uploadPhotoCertificate());
  const response = await createPhotoCertificate(payload);
  if (response.data) {
    await dispatch(uploadPhotoCertificateSuccess(response.data));
  } else {
    dispatch(uploadPhotoCertificateFailed(response));
    openNotificationWithIcon(
      'error',
      'Upload photo certificate',
      response.data.message
    );
  }
};

const uploadCertificateFinal = () => ({ type: UPLOAD_CERTIFICATE_FINAL });

const uploadCertificateFinalFailed = (payload) => ({
  type: UPLOAD_CERTIFICATE_FINAL_FAILED,
  payload,
});

const uploadCertificateFinalSuccess = (payload) => ({
  type: UPLOAD_CERTIFICATE_FINAL_SUCCESS,
  payload,
});

export const uploadCertificateFinalRequest = (payload) => async (dispatch) => {
  dispatch(uploadCertificateFinal());
  const response = await uploadCertificate(payload);
  if (response.data && response.status === 201) {
    await dispatch(uploadCertificateFinalSuccess(response.data));
    dispatch({ type: 'UPLOAD_COMPLETED', payload: true });
  } else {
    dispatch(uploadCertificateFinalFailed(response));
    openNotificationWithIcon(
      'error',
      'Upload certificate final',
      response.data.message
    );
  }
};
