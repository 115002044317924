import React from 'react';
import { Spin } from 'antd';

const styles = {
  width: '100%',
  height: '100%',
  margin: '10rem 0',
  fontSize: '1.5rem',
};

const Loader = () => {
  return <Spin tip="Loading..." style={styles} />;
};

export default Loader;
