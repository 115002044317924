import { photographerShoots } from 'api/Photographer';
import { message } from 'antd';

export const GET_IN_PROGRESS_SHOOTS = 'GET_IN_PROGRESS_SHOOTS';
export const GET_IN_PROGRESS_SHOOTS_SUCCESS = 'GET_IN_PROGRESS_SHOOTS_SUCCESS';
export const GET_IN_PROGRESS_SHOOTS_FAILED = 'GET_IN_PROGRESS_SHOOTS_FAILED';

const getInProgressShoots = () => ({ type: GET_IN_PROGRESS_SHOOTS });

const getInProgressShootsFailed = (payload) => ({
  type: GET_IN_PROGRESS_SHOOTS_FAILED,
  payload,
});

const getInProgressShootsSuccess = (payload) => ({
  type: GET_IN_PROGRESS_SHOOTS_SUCCESS,
  payload,
});

export const setInProgressShoots = (payload) => async (dispatch) => {
  dispatch(getInProgressShoots());
  const response = await photographerShoots(
    payload.status,
    payload.page,
    payload.limit
  );
  if (response.data) {
    await dispatch(getInProgressShootsSuccess(response.data));
  } else {
    dispatch(getInProgressShootsFailed(response));
    message.error('There was an error retrieving your shoots!');
  }
};
