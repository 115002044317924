import jwt from 'jsonwebtoken';

export const isTokenValid = () => {
  const token = localStorage.getItem('token');
  const decoded = jwt.decode(token);
  if (decoded) {
    return true;
  } else {
    return false;
  }
};

export const decodedUser = () => {
  const token = localStorage.getItem('token');
  const decoded = jwt.decode(token);
  return decoded;
};

export const decodeUser = (jwtString) => {
  return jwt.decode(jwtString);
};

export const setToken = (jwt) => {
  localStorage.setItem('token', jwt);
};

export const clearToken = () => {
  localStorage.removeItem('token');
};
