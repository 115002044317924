import React from 'react';
import { Menu, Badge } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  CameraOutlined,
  UserOutlined,
  CalendarOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { clearToken } from 'utils/Auth';
import { isMobile } from 'react-device-detect';

const styles = {
  badge: {
    fontSize: '10px',
    background: '#FF4D4F',
    borderColor: '#FF4D4F',
    margin: ' 0rem 1rem',
  },
};

const { SubMenu } = Menu;

const PhotographerNavigation = () => {
  const history = useHistory();
  const inprogressShoots = useSelector(
    ({ ShootsReducer }) => ShootsReducer.inprogressShoots
  );

  const logoutUser = () => {
    clearToken();
    history.push('/login');
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={['0']}
      defaultOpenKeys={isMobile ? ['100'] : ['0', '1']}
    >
      <SubMenu key="0" icon={<CameraOutlined />} title="Shoots">
        <Menu.Item key={'a'}>
          <Link to={'/uploadstation'}>Upload Station</Link>
          {inprogressShoots.meta && inprogressShoots.meta.totalItems > 0 && (
            <Badge
              style={styles.badge}
              count={inprogressShoots.meta.totalItems}
              overflowCount={20}
            />
          )}
        </Menu.Item>
        <div className="second-step" />
        <Menu.Item key={'b'}>
          <Link to={'/shoots'}>My Shoots</Link>
        </Menu.Item>
        <div className="third-step" />
      </SubMenu>
      <SubMenu key="1" icon={<UserOutlined />} title="Account">
        <Menu.Item key={'d'}>
          <Link to={'/profilesettings/profile'}>Profile Settings</Link>
        </Menu.Item>
        <div className="fourth-step" />
      </SubMenu>
      <Menu.Item key={'2'} icon={<CalendarOutlined />}>
        <Link to={'/calendar'}>Calendar</Link>
      </Menu.Item>
      <div className="fith-step" />
      <Menu.Item
        key={'3'}
        icon={<LogoutOutlined />}
        onClick={() => logoutUser()}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default PhotographerNavigation;
