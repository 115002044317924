import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import { SEGMENT_KEY } from 'config';

export const analytics = Analytics({
  app: 'app-frontend',
  plugins: [
    segmentPlugin({
      writeKey: SEGMENT_KEY,
    }),
  ],
});
