import FormData from 'form-data';
import * as axios from 'axios';
import { API_URL } from 'config';
import { createApi } from './index';

export const photographerShoots = async (status, page, limit) => {
  const API = createApi();
  try {
    const response = await API.get(
      `/photographers/shoots/${status}?&page=${page}&limit=${limit}`
    );
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const photographerShootRequest = async (requestId, secretToken) => {
  const API = createApi();
  try {
    const response = await API.get(
      `shoots/shoot_requests/${requestId}/${secretToken}`
    );
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const photographerShootAmendment = async (secretToken) => {
  const API = createApi();
  try {
    const response = await API.get(`shoot_amendment/${secretToken}`);
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const photographerResponseShootRequest = async (
  requestId,
  secretToken,
  action
) => {
  const API = createApi();
  try {
    const response = await API.patch(
      `shoots/shoot_requests/${requestId}/${action}`,
      { shoot_request_secret: secretToken }
    );
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const photographerResponseShootAmendment = async (
  secretToken,
  accepted
) => {
  const API = createApi();
  try {
    const response = await API.put(`shoot_amendment/${secretToken}`, {
      accepted: accepted,
    });
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const updatePhotographer = async (user, updateVerification) => {
  let equipment_verified =
    updateVerification && user.photographer.equipment_verified == 'verified'
      ? 'unverified'
      : user.photographer.equipment_verified;

  const API = createApi();
  const payload = {
    latitude: user.photographer.location.coordinates[1].toString(),
    longitude: user.photographer.location.coordinates[0].toString(),
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    address: user.photographer.address,
    postcode: user.photographer.postcode,
    city: user.photographer.city,
    gender: user.photographer.gender,
    bio: user.photographer.bio,
    birthday: new Date(user.photographer.birthday),
    deactivated_at: user.photographer.deactivated_at
      ? new Date(user.photographer.deactivated_at)
      : null,
    prof_since: user.photographer.prof_since,
    website: user.photographer.website,
    vat_number: user.photographer.vat_number,
    photographer_stripe_id: user.photographer.photographer_stripe_id,
    verify_id: user.verify_id ? user.verify_id : null,
    cameras: user.photographer.cameras,
    lenses: user.photographer.lenses,
    accessories: user.photographer.accessories,
    user_id: user.id,
    equipment_verified: equipment_verified,
  };
  try {
    const response = await API.put(`/photographers`, payload);
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const updatePhotographerAvatar = async (file) => {
  let form = new FormData();
  form.append('avatar', file, file.name);
  const API_AVATAR = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  try {
    const response = await API_AVATAR.put(`/photographers`, form);
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const verifyPhone = async (phone) => {
  const API = createApi();
  try {
    const response = await API.post(`/users/verify_phone`, phone);
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const checkVerifyPhone = async (payload) => {
  const API = createApi();
  try {
    const response = await API.post(`/users/check_verify_phone`, payload);
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};
