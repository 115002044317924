import React from 'react';
import { Image } from 'antd';

const Logo = ({ type, style, width }) => {
  return (
    <Image
      preview={false}
      width={width}
      style={style}
      alt="Perfocal logo"
      src={`https://dkk5oy4zs0vc4.cloudfront.net/logo-${type}.svg`}
    />
  );
};

export default Logo;
