import {
  photographerLoginAPI,
  recoverPasswordAPI,
  resetPasswordAPI,
} from 'api/Authentication';
import { openNotificationWithIcon } from 'actions/notification';
import { setToken, decodeUser } from 'utils/Auth';

export const PHOTOGRAPHER_LOGIN = 'PHOTOGRAPHER_LOGIN';
export const PHOTOGRAPHER_LOGIN_SUCCESS = 'PHOTOGRAPHER_LOGIN_SUCCESS';
export const PHOTOGRAPHER_LOGIN_FAILED = 'PHOTOGRAPHER_LOGIN_FAILED';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILED = 'RECOVER_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const SET_USER_DATA = 'SET_USER_DATA';

export const setUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
});

const photographerLogin = () => ({ type: PHOTOGRAPHER_LOGIN });

const photographerLoginFailed = (payload) => ({
  type: PHOTOGRAPHER_LOGIN_FAILED,
  payload,
});

const photographerLoginSuccess = (payload) => ({
  type: PHOTOGRAPHER_LOGIN_SUCCESS,
  payload,
});

export const photographerLoginRequest = (history, credentials) => async (
  dispatch
) => {
  dispatch(photographerLogin());
  const response = await photographerLoginAPI(credentials);
  if (response.data && response.status === 201) {
    await dispatch(photographerLoginSuccess(response.data));
    setToken(response.data.jwt);
    history.push('/uploadstation');
  } else {
    dispatch(photographerLoginFailed(response));
    openNotificationWithIcon('error', 'Login Error', response.data.message);
  }
};

const recoverPasswordFailed = (payload) => ({
  type: RECOVER_PASSWORD_FAILED,
  payload,
});

const recoverPasswordSuccess = (payload) => ({
  type: RECOVER_PASSWORD_SUCCESS,
  payload,
});

export const recoverPassword = (email) => async (dispatch) => {
  dispatch({ type: RECOVER_PASSWORD });
  const response = await recoverPasswordAPI(email);
  if (response.status === 201) {
    dispatch(recoverPasswordSuccess(response.data));
    openNotificationWithIcon(
      'success',
      'Password Reset',
      'An email containing a password reset link has been sent.'
    );
  } else {
    dispatch(recoverPasswordFailed(response));
    openNotificationWithIcon('error', 'Password Reset', response.data.message);
  }
};

const resetPasswordFailed = (payload) => ({
  type: RESET_PASSWORD_FAILED,
  payload,
});

const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPassword = (history, payload, onboard) => async (
  dispatch
) => {
  dispatch({ type: RESET_PASSWORD });
  const response = await resetPasswordAPI(payload);
  if (response.status === 201) {
    const user = decodeUser(response.data.jwt);
    dispatch(resetPasswordSuccess(response));
    dispatch(setUserData(user));
    setToken(response.data.jwt);
    if (onboard) {
      history.push('/onboarding/1');
    } else {
      history.push('/uploadstation');
    }
  } else {
    dispatch(resetPasswordFailed(response));
    openNotificationWithIcon('error', 'Password Reset', response.data.message);
  }
};
