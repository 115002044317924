import React, { useState } from 'react';
import Routes from 'routes';
import { useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from 'store';
import { INTERCOM_APP_ID } from 'config';
import Intercom from 'react-intercom';
import { IntercomAPI } from 'react-intercom';
import { analytics } from 'utils/Analytics';
import './App.less';

const store = configureStore();
const intercomProps = {
  hide_default_launcher: false,
};

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    analytics.page(location.pathname);
  }, [location]);
}

function App() {
  usePageViews();
  IntercomAPI('boot', {
    app_id: INTERCOM_APP_ID,
  });
  return (
    <Provider store={store}>
      <Routes />
      <Intercom appID={INTERCOM_APP_ID} {...intercomProps} />
    </Provider>
  );
}

export default App;
