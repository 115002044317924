import { message } from 'antd';
import {
  photographerShootRequest,
  photographerResponseShootRequest,
  photographerResponseShootAmendment,
  photographerShootAmendment,
} from 'api/Photographer';

export const SET_SHOOT_REQUEST = 'SET_SHOOT_REQUEST';
export const SET_SHOOT_REQUEST_SUCCESS = 'SET_SHOOT_REQUEST_SUCCESS';
export const SET_SHOOT_REQUEST_FAILED = 'SET_SHOOT_REQUEST_FAILED';

export const SET_SHOOT_AMENDMENT = 'SET_SHOOT_AMENDMENT';
export const SET_SHOOT_AMENDMENT_SUCCESS = 'SET_SHOOT_AMENDMENT_SUCCESS';
export const SET_SHOOT_AMENDMENT_FAILED = 'SET_SHOOT_AMENDMENT_FAILED';

export const ACTION_SHOOT_REQUEST = 'ACTION_SHOOT_REQUEST';
export const ACTION_SHOOT_REQUEST_SUCCESS = 'ACTION_SHOOT_REQUEST_SUCCESS';
export const ACTION_SHOOT_REQUEST_FAILED = 'ACTION_SHOOT_REQUEST_FAILED';

export const ACTION_SHOOT_AMENDMENT = 'ACTION_SHOOT_AMENDMENT';
export const ACTION_SHOOT_AMENDMENT_SUCCESS = 'ACTION_SHOOT_AMENDMENT_SUCCESS';
export const ACTION_SHOOT_AMENDMENT_FAILED = 'ACTION_SHOOT_AMENDMENT_FAILED';

//GET SHOOT REQUEST DATA
const setShootRequest = () => ({ type: SET_SHOOT_REQUEST });

const setShootRequestSuccess = (payload) => ({
  type: SET_SHOOT_REQUEST_SUCCESS,
  payload,
});

const setShootRequestFailed = (payload) => ({
  type: SET_SHOOT_REQUEST_FAILED,
  payload,
});

export const setShootRequestData = (requestId, secretToken) => async (
  dispatch
) => {
  dispatch(setShootRequest());
  const response = await photographerShootRequest(requestId, secretToken);
  if (response.status === 201 || response.status === 200) {
    await dispatch(setShootRequestSuccess(response.data));
  } else {
    await dispatch(setShootRequestFailed(response));
  }
};

//GET SHOOT AMENDMENT DATA
const setShootAmendment = () => ({ type: SET_SHOOT_AMENDMENT });

const setShootAmendmentSuccess = (payload) => ({
  type: SET_SHOOT_AMENDMENT_SUCCESS,
  payload,
});

const setShootAmendmentFailed = (payload) => ({
  type: SET_SHOOT_AMENDMENT_FAILED,
  payload,
});

export const setShootAmendmentData = (secretToken) => async (dispatch) => {
  dispatch(setShootAmendment());
  const response = await photographerShootAmendment(secretToken);
  if (response.status === 201 || response.status === 200) {
    await dispatch(setShootAmendmentSuccess(response.data));
  } else {
    await dispatch(setShootAmendmentFailed(response));
  }
};

//RESPOND TO SHOOT REQUEST
const actionShootRequest = () => ({ type: ACTION_SHOOT_REQUEST });

const actionShootRequestSuccess = (payload) => ({
  type: ACTION_SHOOT_REQUEST_SUCCESS,
  payload,
});

const actionShootRequestFailed = (payload) => ({
  type: ACTION_SHOOT_REQUEST_FAILED,
  payload,
});

export const actionShootRequestResponse = (
  requestId,
  secretToken,
  action,
  history
) => async (dispatch) => {
  dispatch(actionShootRequest());
  const response = await photographerResponseShootRequest(
    requestId,
    secretToken,
    action
  );
  if (response.status === 201 || response.status === 200) {
    await dispatch(actionShootRequestSuccess(response.data));
    message.success(`You have ${action}ed this job successfully!`);
    history.push('/uploadstation');
  } else {
    await dispatch(actionShootRequestFailed(response));
    message.error(`There was an error while replying to this job!`);
  }
};

//RESPOND TO SHOOT AMENDMENT
const actionShootAmendment = () => ({ type: ACTION_SHOOT_AMENDMENT });

const actionShootAmendmentSuccess = (payload) => ({
  type: ACTION_SHOOT_REQUEST_SUCCESS,
  payload,
});

const actionShootAmendmentFailed = (payload) => ({
  type: ACTION_SHOOT_AMENDMENT_FAILED,
  payload,
});

export const actionShootAmendmentResponse = (
  secretToken,
  action,
  history
) => async (dispatch) => {
  dispatch(actionShootAmendment());
  let accepted = action === 'accept' ? true : false;
  const response = await photographerResponseShootAmendment(
    secretToken,
    accepted
  );
  if (response.status === 201 || response.status === 200) {
    await dispatch(actionShootAmendmentSuccess(response.data));
    message.success(`You have ${action}ed this job amendment successfully!`);
    history.push('/uploadstation');
  } else {
    await dispatch(actionShootAmendmentFailed(response));
    message.error(`There was an error while replying to this job amendment!`);
  }
};
