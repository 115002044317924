import {
  PHOTOGRAPHER_LOGIN,
  PHOTOGRAPHER_LOGIN_SUCCESS,
  PHOTOGRAPHER_LOGIN_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  SET_USER_DATA,
} from 'actions/authorization';

const initialState = {
  isLoading: true,
  error: false,
  message: '',
  user: {},
  photographerId: '',
};

const AuthorizationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PHOTOGRAPHER_LOGIN:
      return {
        ...state,
        message: payload,
      };
    case PHOTOGRAPHER_LOGIN_SUCCESS:
      return {
        ...state,
        message: payload,
      };
    case PHOTOGRAPHER_LOGIN_FAILED:
      return {
        ...state,
        error: true,
        message: payload.data.message,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: payload,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};
export default AuthorizationReducer;
