import API from './index';
import { API_URL } from 'config';

export const photographerLoginAPI = async (credentials) => {
  try {
    return await API.post(`${API_URL}/users/tog/login`, credentials);
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const recoverPasswordAPI = async (email) => {
  try {
    return await API.post(`${API_URL}/users/recover_password`, email);
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const resetPasswordAPI = async (payload) => {
  try {
    return await API.post(`${API_URL}/users/reset_password`, payload);
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};
