export const styles = {
  layout: {
    height: '100vh',
  },
  header: {
    padding: '0',
  },
  logo: {
    margin: '1rem',
  },
  burger: {
    marginLeft: '13.5rem',
  },
  content: {
    margin: '24px 16px',
    padding: '24',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  sider: {
    position: 'fixed',
    zIndex: '999',
    height: '100%',
  },
};
