import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import Footer from 'components/_Footer';
import Logo from 'components/Logo';
import { styles } from './styles.js';
import './index.css';
import { Link } from 'react-router-dom';

const { Content, Sider, Header } = Layout;

const DashboardLayout = (props) => {
  const [collapsed, toggle] = useState(isMobile ? true : false);
  const { children, navigation } = props;

  return (
    <Layout style={styles.layout}>
      <Sider
        trigger={null}
        breakpoint={'md'}
        collapsible
        collapsed={collapsed}
        collapsedWidth={isMobile ? 0 : 80}
        width={230}
        style={isMobile ? styles.sider : null}
      >
        <Link to="/uploadstation">
          <Logo type="light" style={styles.logo} />
        </Link>
        {navigation}
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={styles.header}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              style: isMobile ? (!collapsed ? styles.burger : null) : null,
              onClick: () => toggle(!collapsed),
            }
          )}
        </Header>
        <Content style={styles.content}>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
  navigation: PropTypes.node,
};

export default DashboardLayout;
