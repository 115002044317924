import { createApi } from './index';
import { API_URL } from 'config';

export const createPhotoCertificate = async (payload) => {
  const API = createApi();
  try {
    const response = await API.post(`${API_URL}/photos/certificate`, payload);
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const uploadCertificate = async (payload) => {
  const API = createApi();
  try {
    const response = await API.post(`${API_URL}/certificates`, payload);
    return response;
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};
