// export const API_URL = `http://localhost:3000/api`;
export const API_URL = `https://${process.env.REACT_APP_DOMAIN}/api`;
export const FILES_URL = process.env.REACT_APP_FILES_URL;
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL;
export const Domain = process.env.REACT_APP_DOMAIN;
export const APP_ENV = process.env.REACT_APP_ENV;
export const AWS_KEY_ID = 'AKIAIZBR62IUZYDTVD5A';
export const AWS_KEY_SECRET = '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';
export const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;
