import React from 'react';
import { isTokenValid } from 'utils/Auth';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isTokenValid() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);
