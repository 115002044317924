import {
  SET_SHOOT_REQUEST,
  SET_SHOOT_REQUEST_FAILED,
  SET_SHOOT_REQUEST_SUCCESS,
  SET_SHOOT_AMENDMENT,
  SET_SHOOT_AMENDMENT_FAILED,
  SET_SHOOT_AMENDMENT_SUCCESS,
  ACTION_SHOOT_REQUEST,
  ACTION_SHOOT_REQUEST_FAILED,
  ACTION_SHOOT_REQUEST_SUCCESS,
  ACTION_SHOOT_AMENDMENT,
  ACTION_SHOOT_AMENDMENT_FAILED,
  ACTION_SHOOT_AMENDMENT_SUCCESS,
} from 'actions/jobs';

const initialState = {
  loading: false,
  error: null,
  shootRequest: {},
};

export const JobsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SHOOT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_SHOOT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        shootRequest: payload,
      };
    case SET_SHOOT_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SET_SHOOT_AMENDMENT:
      return {
        ...state,
        loading: true,
      };
    case SET_SHOOT_AMENDMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        shootRequest: payload,
      };
    case SET_SHOOT_AMENDMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ACTION_SHOOT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_SHOOT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_SHOOT_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ACTION_SHOOT_AMENDMENT:
      return {
        ...state,
        loading: true,
      };
    case ACTION_SHOOT_AMENDMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_SHOOT_AMENDMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
