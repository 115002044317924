import {
  UPLOAD_PHOTO_CERTIFICATE,
  UPLOAD_PHOTO_CERTIFICATE_SUCCESS,
  UPLOAD_PHOTO_CERTIFICATE_FAILED,
  UPLOAD_CERTIFICATE_FINAL,
  UPLOAD_CERTIFICATE_FINAL_SUCCESS,
  UPLOAD_CERTIFICATE_FINAL_FAILED,
} from 'actions/category';

const initialState = {
  isLoading: false,
  message: '',
  uploadedPhotos: [],
  error: false,
};

export const CategoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPLOAD_PHOTO_CERTIFICATE:
      return {
        ...state,
        isLoading: true,
        message: payload,
      };
    case UPLOAD_PHOTO_CERTIFICATE_SUCCESS:
      const photoId = payload.id;
      return {
        ...state,
        isLoading: false,
        uploadedPhotos: [...state.uploadedPhotos, photoId],
      };
    case UPLOAD_PHOTO_CERTIFICATE_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        message: payload,
      };
    case UPLOAD_CERTIFICATE_FINAL:
      return {
        ...state,
        isLoading: true,
        message: payload,
      };
    case UPLOAD_CERTIFICATE_FINAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPLOAD_CERTIFICATE_FINAL_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};
