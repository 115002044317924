import { verifyPhone, checkVerifyPhone } from 'api/Photographer';
import { getCategoriesAPI, onboardingApi } from 'api/Onboarding';
import { message } from 'antd';

export const SET_STEP = 'SET_STEP';
export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';
export const GO_PREVIOUS_STEP = 'GO_PREVIOUS_STEP';
export const SET_STEP_AS_VERIFIED = 'SET_STEP_AS_VERIFIED';

export const SET_VALUE = 'SET_VALUE';
export const SET_VALUE_EQUIPMENT = 'SET_VALUE_EQUIPMENT';

export const SET_VALUE_PHONE = 'SET_VALUE_PHONE';

export const VERIFY_PHONE = 'VERIFY_PHONE';
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_FAILED = 'VERIFY_PHONE_FAILED';

export const CHECK_VERIFY = 'CHECK_VERIFY';
export const CHECK_VERIFY_SUCCESS = 'CHECK_VERIFY_SUCCESS';
export const CHECK_VERIFY_FAILED = 'CHECK_VERIFY_FAILED';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';

export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const SET_ADDRESS_DETAILS = 'SET_ADDRESS_DETAILS';
export const SET_LONG_LAT = 'SET_LONG_LAT';

export const CHECK_GENERAL_VALIDATION = 'CHECK_GENERAL_VALIDATION';
export const CHECK_EQUIPMENT_VALIDATION = 'CHECK_EQUIPMENT_VALIDATION';

export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED';

export const SUBMIT_ONBOARDING = 'SUBMIT_ONBOARDING';
export const SUBMIT_ONBOARDING_FAILED = 'SUBMIT_ONBOARDING_FAILED';
export const SUBMIT_ONBOARDING_SUCCESS = 'SUBMIT_ONBOARDING_SUCCESS';

export const checkEquipmentValidation = () => async (dispatch) => {
  dispatch({ type: CHECK_EQUIPMENT_VALIDATION });
};

export const checkGeneralValidation = () => async (dispatch) => {
  dispatch({ type: CHECK_GENERAL_VALIDATION });
};

export const setAddressDetails = (payload) => async (dispatch) => {
  dispatch({ type: SET_ADDRESS_DETAILS, payload });
};

export const setLongLat = (payload) => async (dispatch) => {
  dispatch({ type: SET_LONG_LAT, payload });
};

export const nextStep = (history, step) => async (dispatch) => {
  dispatch({ type: GO_TO_NEXT_STEP });
  const nextStep = step + 1;
  history.push(`/onboarding/${nextStep}`);
};

export const backStep = (history, step) => async (dispatch) => {
  dispatch({ type: GO_PREVIOUS_STEP });
  const backStep = step - 1;
  history.push(`/onboarding/${backStep}`);
};

export const setStep = (history, step) => async (dispatch) => {
  dispatch({ type: SET_STEP, payload: { step } });
  history.push(`/onboarding/${step}`);
};

export const setValue = (payload) => async (dispatch) => {
  dispatch({ type: SET_VALUE, payload });
};

export const setValuePhone = (payload) => async (dispatch) => {
  dispatch({ type: SET_VALUE_PHONE, payload });
};

export const setValueEquipment = (payload) => async (dispatch) => {
  dispatch({ type: SET_VALUE_EQUIPMENT, payload });
};

export const setStepAsVerified = (step) => ({
  type: SET_STEP_AS_VERIFIED,
  payload: step,
});

const verifyPhoneRequest = () => ({ type: VERIFY_PHONE });

const verifyPhoneRequestSuccess = (payload) => ({
  type: VERIFY_PHONE_SUCCESS,
  payload,
});

const verifyPhoneRequestFailed = (payload) => ({
  type: VERIFY_PHONE_FAILED,
  payload,
});

export const verifyPhoneNumber = (payload) => async (dispatch) => {
  dispatch(verifyPhoneRequest());
  const response = await verifyPhone(payload);
  if (response.status === 201) {
    await dispatch(verifyPhoneRequestSuccess(response.data, payload));
    message.success(
      `You should receive a text message with a secret code soon!`
    );
  } else {
    dispatch(verifyPhoneRequestFailed(response));
    message.error(response.data.message);
  }
};

const checkVerify = () => ({ type: CHECK_VERIFY });

const checkVerifySuccess = (payload) => ({
  type: CHECK_VERIFY_SUCCESS,
  payload,
});

const checkVerifyFailed = (payload) => ({
  type: CHECK_VERIFY_FAILED,
  payload,
});

export const checkVerifyNumber = (payload) => async (dispatch) => {
  dispatch(checkVerify());
  const response = await checkVerifyPhone(payload);
  if (response.status === 201) {
    await dispatch(checkVerifySuccess(response.data));
    message.success(`Your new phone number is verified!`);
  } else {
    dispatch(checkVerifyFailed(response));
    message.error(response.data.message);
  }
};

const getPackagesFailed = (payload) => ({
  type: GET_CATEGORIES,
  payload,
});

const getPackagesSuccess = (payload) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload,
});

export const getCategories = () => async (dispatch) => {
  dispatch({ type: GET_CATEGORIES });
  const response = await getCategoriesAPI();
  if (response.status === 200) {
    await dispatch(getPackagesSuccess(response.data));
  } else {
    dispatch(getPackagesFailed(response));
  }
};

export const selectCategory = (category) => async (dispatch) => {
  dispatch({ type: SELECT_CATEGORY, payload: category });
};

export const submitOnboardingFailed = (payload) => ({
  type: SUBMIT_ONBOARDING_FAILED,
  payload,
});

export const submitOnboardingSuccess = (payload) => ({
  type: SUBMIT_ONBOARDING_SUCCESS,
  payload,
});

export const submitOnboarding = (history, payload) => async (dispatch) => {
  dispatch({ type: SUBMIT_ONBOARDING });
  const response = await onboardingApi(payload);
  if (response.status === 201) {
    await dispatch(submitOnboardingSuccess(response.data));
    history.push('/uploadstation', { onboarding: true });
    window.location.reload();
  } else {
    dispatch(submitOnboardingFailed(response));
  }
};
