import React, { lazy } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
// import Extras from 'containers/Photographer/Onboarding/Extras';

const VerifyMobile = lazy(() =>
  import('containers/Photographer/Onboarding/VerifyMobile')
);
const GeneralInformation = lazy(() =>
  import('containers/Photographer/Onboarding/GeneralInformation')
);
const Photography = lazy(() =>
  import('containers/Photographer/Onboarding/Photography')
);
const Genre = lazy(() => import('containers/Photographer/Onboarding/Genre'));
const CategoryUpload = lazy(() =>
  import('containers/Photographer/Onboarding/CategoryUpload')
);
const Confirm = lazy(() =>
  import('containers/Photographer/Onboarding/Confirm')
);
const Address = lazy(() =>
  import('containers/Photographer/Onboarding/Address')
);

const Router = () => {
  return (
    <BrowserRouter>
      <Redirect to="/onboarding/1" />
      <Switch>
        <VerifyMobile exact path="/onboarding/1" />
        <Address path="/onboarding/2" />
        <GeneralInformation path="/onboarding/3" />
        <Photography path="/onboarding/4" />
        <Genre path="/onboarding/5" />
        <CategoryUpload path="/onboarding/6" />
        <Confirm path="/onboarding/7" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
