import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import PhotographerRoutes from './PhotographerRoutes';
import OnboardingRoutes from './OnboardingRoutes';
import Loader from 'components/Loader';

const Login = lazy(() => import('containers/Photographer/Auth/Login'));
const RecoverPassword = lazy(() =>
  import('containers/Photographer/Auth/RecoverPassword')
);
const ResetPassword = lazy(() =>
  import('containers/Photographer/Auth/ResetPassword')
);
const Jobs = lazy(() => import('containers/Photographer/Jobs'));

const Router = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* Authentication routes */}
        <Route path="/login" component={Login} />
        <Route path="/recover" component={RecoverPassword} />
        <Route path="/verified" component={ResetPassword} />

        <Route path="/onboarding" component={OnboardingRoutes} />

        <Route path="/event-requests" component={Jobs} />
        <Route path="/amendment-requests" component={Jobs} />

        {/* Private routes */}
        <PrivateRoute path="/" component={PhotographerRoutes} />
      </Switch>
    </Suspense>
  );
};

export default Router;
