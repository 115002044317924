import { createApi } from './index';

export const getCategoriesAPI = async () => {
  try {
    const API = createApi();
    return await API.get(`categories`);
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};

export const onboardingApi = async (payload) => {
  try {
    const API = createApi();
    return await API.post(`/photographers/onboarding`, payload);
  } catch (error) {
    const { data } = error.response;
    return await { success: false, data };
  }
};
